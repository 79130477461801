/* rambla-400normal - latin */
@font-face {
  font-family: 'Rambla';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Rambla Regular '),
    local('Rambla-Regular'),
    url('./files/rambla-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rambla-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* rambla-400italic - latin */
@font-face {
  font-family: 'Rambla';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Rambla Regular italic'),
    local('Rambla-Regularitalic'),
    url('./files/rambla-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rambla-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* rambla-700normal - latin */
@font-face {
  font-family: 'Rambla';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Rambla Bold '),
    local('Rambla-Bold'),
    url('./files/rambla-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rambla-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* rambla-700italic - latin */
@font-face {
  font-family: 'Rambla';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Rambla Bold italic'),
    local('Rambla-Bolditalic'),
    url('./files/rambla-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rambla-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

